import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import { Loading } from "aws-amplify-react";
import { oneLineTrim } from "common-tags";

function SignOut({ redirectToUriAfterLogout }) {
  useEffect(() => {
    async function signOut() {
      const secure = process.env.REACT_APP_COOKIE_DOMAIN !== "localhost";
      const loginSessionCookie = oneLineTrim`
        loginsession=logout;
        max-age=0;
        domain=${process.env.REACT_APP_COOKIE_DOMAIN};
        path=/;
        ${secure ? "secure;" : ""}
        SameSite=lax
      `;
      document.cookie = loginSessionCookie;

      const loginSubCookie = oneLineTrim`
        loginsub=0;
        max-age=0;
        domain=${process.env.REACT_APP_COOKIE_DOMAIN};
        path=/;
        ${secure ? "secure;" : ""}
        SameSite=lax
      `;
      document.cookie = loginSubCookie;

      try {
        await Auth.signOut();
        await Auth.signOut({ global: true });
      } catch (e) {
        console.log(e);
      } finally {
        window.location.replace(redirectToUriAfterLogout);
      }
    }

    signOut();
  }, [redirectToUriAfterLogout]);

  return <Loading />;
}

export default SignOut;
