import React from "react";
import {
  Authenticator as AmplifyAuthenticator,
  Greetings,
  ConfirmSignUp,
  ForgotPassword,
  SignIn,
  SignUp,
} from "aws-amplify-react";

import Redirect from "./redirect";
import CustomConfirmSignUp from "./confirm-signup";
import CustomForgotPassword from "./forgot-password";
import CustomForgotUsername from "./forgot-username";
import CustomSignIn from "./sign-in";
import CustomSignUp from "./sign-up"; // see https://github.com/aws-amplify/amplify-js/pull/3340

import theme from "../theme";

const signUpConfig = {
  hiddenDefaults: ["phone_number"],
  signUpFields: [
    {
      label: "Username",
      key: "username",
      required: true,
      placeholder: "Username",
      displayOrder: 1,
    },
    {
      label: "Password",
      key: "password",
      required: true,
      placeholder: "Password",
      type: "password",
      displayOrder: 2,
    },
    {
      label: "Email",
      key: "email",
      required: true,
      placeholder: "Email",
      type: "email",
      displayOrder: 3,
    },
    {
      label: "Confirm Email",
      key: "confirm_email",
      placeholder: "E-Mail bestätigen",
      required: true,
      displayOrder: 4,
    },
  ],
};

function Authenticator({ validationData, initialAuthState }) {
  return (
    <>
      <AmplifyAuthenticator
        authState={initialAuthState}
        theme={theme}
        hide={[Greetings, ConfirmSignUp, ForgotPassword, SignIn, SignUp]}
      >
        <CustomConfirmSignUp
          override={"ConfirmSignUp"}
          validationData={validationData}
        />
        <CustomForgotPassword override={"ForgotPassword"} />
        <CustomSignIn override={"SignIn"} validationData={validationData} />
        <CustomSignUp
          override={"SignUp"}
          validationData={validationData}
          signUpConfig={signUpConfig}
        />
        <CustomForgotUsername />
        <Redirect validationData={validationData} />
      </AmplifyAuthenticator>
    </>
  );
}

export default Authenticator;
