import React from "react";
import styled from "@emotion/styled";

import { I18n, ConsoleLogger as Logger } from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import { AuthPiece, AmplifyTheme } from "aws-amplify-react";

import {
  Box,
  ScrollToTop,
  SubmitButton,
  passwordValidator,
  ValidationResult,
} from "@ewe-it/ewe-design-react";
import { scrollToTop } from "@ewe-it/ewe-frontend-utils";

import {
  FormSection,
  SectionHeader,
  SectionBody,
  SectionFooter,
  Input,
  Link,
  SectionFooterPrimaryContent,
  SectionFooterSecondaryContent,
  FormField,
  InputLabel,
} from "aws-amplify-react/lib/Amplify-UI/Amplify-UI-Components-React";
import { auth } from "aws-amplify-react/lib/Amplify-UI/data-test-attributes";

const TextBox = styled(Box)`
  padding-bottom: 20px;
`;

const Text = styled.span`
  font-size: 13px;
  color: #333333;
`;

const logger = new Logger("CustomForgotPassword");

function UsernameForm(props) {
  return (
    <form onSubmit={props.onSubmit}>
      <FormSection
        theme={props.theme}
        data-test={props.auth.forgotPassword.section}
      >
        <ScrollToTop key="forgot-password-form" />
        <SectionHeader
          theme={props.theme}
          data-test={props.auth.forgotPassword.headerSection}
        >
          {I18n.get("Reset your password")}
        </SectionHeader>
        <SectionBody
          theme={props.theme}
          data-test={props.auth.forgotPassword.bodySection}
        >
          <div>
            <FormField theme={props.theme}>
              <InputLabel theme={props.theme}>
                {I18n.get(props.usernameLabel)} *
              </InputLabel>
              <Input
                defaultValue={props.username}
                autoFocus
                placeholder={I18n.get("Enter your username")}
                theme={props.theme}
                key="username"
                name="username"
                autoComplete="username"
                autoCapitalize="off"
                disabled={props.loading}
                onChange={props.onChange}
                data-test={props.auth.genericAttrs.usernameInput}
              />
            </FormField>
          </div>
        </SectionBody>
        <SectionFooter theme={props.theme}>
          <SectionFooterPrimaryContent theme={props.theme}>
            <SubmitButton
              data-test={props.auth.forgotPassword.sendCodeButton}
              loading={props.loading}
            >
              {I18n.get("Send Code")}
            </SubmitButton>
          </SectionFooterPrimaryContent>
          <SectionFooterSecondaryContent theme={props.theme}>
            <Link
              theme={props.theme}
              onClick={() => props.changeState("signIn")}
              data-test={props.auth.forgotPassword.backToSignInLink}
            >
              {I18n.get("Back to Sign In")}
            </Link>
          </SectionFooterSecondaryContent>
        </SectionFooter>
      </FormSection>
    </form>
  );
}

const ErrorBox = styled(Box)`
  padding: 0 0 20px 0;
`;

function ConfirmationCodeForm(props) {
  return (
    <form onSubmit={props.onSubmit}>
      <FormSection
        theme={props.theme}
        data-test={props.auth.forgotPassword.section}
      >
        <ScrollToTop key="forgot-password-confirmation" />
        <SectionHeader
          theme={props.theme}
          data-test={props.auth.forgotPassword.headerSection}
        >
          {I18n.get("Reset your password")}
        </SectionHeader>
        <SectionBody
          theme={props.theme}
          data-test={props.auth.forgotPassword.bodySection}
        >
          <div>
            {props.authState === "resetPassword" && (
              <TextBox>
                <Text>
                  {I18n.get(
                    "We've improved the security of your login for you. Please reset your password once for technical reasons",
                  )}
                </Text>
              </TextBox>
            )}
            <TextBox>
              <Text>
                {I18n.get(
                  "Please enter the confirmation code that we have sent to your e-mail address.",
                )}
              </Text>
            </TextBox>
            <Input
              placeholder={I18n.get("Code")}
              theme={props.theme}
              key="code"
              name="code"
              autoComplete="off"
              disabled={props.loading}
              onChange={props.onChange}
            />
            <Input
              placeholder={I18n.get("New Password")}
              theme={props.theme}
              type="password"
              key="password"
              name="password"
              disabled={props.loading}
              onChange={props.onChange}
            />
            {props.passwordValidation.length > 0 && (
              <ErrorBox>
                <ValidationResult result={props.passwordValidation} />
              </ErrorBox>
            )}
          </div>
        </SectionBody>
        <SectionFooter theme={props.theme}>
          <SectionFooterPrimaryContent theme={props.theme}>
            <SubmitButton
              data-test={props.auth.forgotPassword.submitButton}
              loading={props.loading}
            >
              {I18n.get("Submit")}
            </SubmitButton>
          </SectionFooterPrimaryContent>
          <SectionFooterSecondaryContent theme={props.theme}>
            <Link
              theme={props.theme}
              onClick={() => props.resendCode()}
              data-test={auth.forgotPassword.resendCodeLink}
            >
              {I18n.get("Resend Code")}
            </Link>
          </SectionFooterSecondaryContent>
        </SectionFooter>
      </FormSection>
    </form>
  );
}

export default class CustomForgotPassword extends AuthPiece {
  constructor(props) {
    super(props);

    this.send = this.send.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.autoSendCode = this.autoSendCode.bind(this);
    this.handleConfirmationCodeSubmit = this.handleConfirmationCodeSubmit.bind(
      this,
    );

    this._validAuthStates = ["forgotPassword", "resetPassword"];
    this.state = {
      passwordValidation: [],
      codeAutosendDone: false,
      delivery: null,
      loading: false,
    };
  }

  send({ isResend }) {
    const { authData = {} } = this.props;
    const username = this.getUsernameFromInput() || authData.username;
    if (!Auth || typeof Auth.forgotPassword !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported",
      );
    }
    this.setState({ loading: true });
    Auth.forgotPassword(username)
      .then(data => {
        logger.debug(data);
        super.error(I18n.get(isResend ? "Code resent!" : "Code sent!"));
        this.setState(prev => ({
          ...prev,
          delivery: data.CodeDeliveryDetails,
          loading: false,
        }));
        scrollToTop();
      })
      .catch(err => {
        this.setState({ loading: false });
        this.error(err);
        scrollToTop();
      });
  }

  handleConfirmationCodeSubmit(event) {
    event.preventDefault();
    const { authData = {} } = this.props;
    const { code, password } = this.inputs;
    const username = this.getUsernameFromInput() || authData.username;

    if (!Auth || typeof Auth.forgotPasswordSubmit !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported",
      );
    }

    if (typeof code !== "string" || code.trim().length < 6) {
      this.error("Bitte geben Sie Ihren Code ein");
      return;
    }

    if (this.state.passwordValidation.some(item => !item.valid)) {
      this.error(
        "Dieses Passwort ist nicht sicher genug. Bitte beachten Sie die Hinweise.",
      );
      return;
    }

    this.setState({ loading: true });

    // Ignore any whitespace in the code since Cognito expects codes without
    // whitespace and doesn't remove whitespace on its own.
    //
    // Removing whitespace prevents the following error message:
    //   1 validation error detected: Value ' XXX XXX' at 'confirmationCode'
    //   failed to satisfy constraint:
    //   Member must satisfy regular expression pattern: [\S]+
    //
    // In Regular Expressions, \s is the "opposite" of \S.
    //   \S matches all non-whitespace characters, while
    //   \s matches all whitespace characters.
    // We use \s to remove all whitespace to ensure the string matches [\S]+.
    const codeWithoutWhitespace = code.replace(/\s/g, "");
    Auth.forgotPasswordSubmit(username, codeWithoutWhitespace, password)
      .then(data => {
        logger.debug(data);
        this.changeState("signIn", {
          infoMessage: I18n.get("Your password was changed successfully."),
        });
        this.setState({ ...this.state, delivery: null, loading: false });
      })
      .catch(err => {
        this.setState({ loading: false });
        this.error(err);
        scrollToTop();
      });
  }

  // Sends confirmation code after a users signs in whose account status is
  // RESET_REQUIRED as they are then forced to change their password.
  autoSendCode() {
    const { authData = {}, authState } = this.props;
    if (
      ((authData && authData.username) || this.state.delivery) &&
      authState === "resetPassword" &&
      !this.state.codeAutosendDone
    ) {
      this.setState(prev => ({
        ...prev,
        codeAutosendDone: true,
        loading: false,
      }));
      this.send({ isResend: false });
    }
  }

  componentDidMount() {
    this.autoSendCode();
  }

  componentDidUpdate() {
    this.autoSendCode();
  }

  handleInputChange(event) {
    super.handleInputChange(event);
    if (event.target.name === "password") {
      const passwordValidation = passwordValidator(
        { newPassword1: this.inputs.password },
        false,
      );
      this.setState(prevState => ({ ...prevState, passwordValidation }));
    }
  }

  showComponent(theme) {
    const { authData = {} } = this.props;

    if (this.state.delivery || authData.username) {
      return (
        <ConfirmationCodeForm
          passwordValidation={this.state.passwordValidation}
          onSubmit={this.handleConfirmationCodeSubmit}
          authState={this.props.authState}
          theme={theme || AmplifyTheme}
          auth={auth}
          loading={this.state.loading}
          onChange={this.handleInputChange}
          resendCode={() => this.send({ isResend: true })}
        />
      );
    }

    return (
      <UsernameForm
        onSubmit={event => {
          event.preventDefault();
          this.send({ isResend: false });
        }}
        theme={theme || AmplifyTheme}
        auth={auth}
        authData={authData}
        usernameLabel={this.getUsernameLabel()}
        username={this.state.username}
        onChange={this.handleInputChange}
        loading={this.state.loading}
        changeState={this.changeState}
      />
    );
  }
}
