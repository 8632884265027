import React from "react";
import styled from "@emotion/styled";
import { I18n } from "@aws-amplify/core";
import { AuthPiece } from "aws-amplify-react";
import { API } from "aws-amplify";
import { Box, ScrollToTop, SubmitButton } from "@ewe-it/ewe-design-react";
import { scrollToTop } from "@ewe-it/ewe-frontend-utils";
import {
  FormSection,
  SectionHeader,
  SectionBody,
  SectionFooter,
  Input,
  Link,
  SectionFooterPrimaryContent,
  SectionFooterSecondaryContent,
  FormField,
  InputLabel,
} from "aws-amplify-react/lib/Amplify-UI/Amplify-UI-Components-React";

const TextBox = styled(Box)`
  padding-bottom: 20px;
`;

const Text = styled.span`
  font-size: 13px;
  color: #333333;
`;

export default class CustomForgotUsername extends AuthPiece {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this._validAuthStates = ["forgotUsername"];
    this.state = { delivery: null, loading: false };
  }

  handleSubmit(event) {
    event.preventDefault();
    const mail = this.inputs.email;

    if (typeof mail !== "string" || mail.trim().length === 0) {
      super.error("Bitte füllen Sie diese Felder aus: E-Mail");
      scrollToTop();
      return;
    }

    this.setState({ loading: true });
    const request =
      process.env.NODE_ENV === "development"
        ? new Promise(resolve => setTimeout(resolve, 500)).then(() => ({
            status: 200,
            data: { success: true },
          }))
        : API.post("AuthPortalServiceAPI", "/forgot-username", {
            headers: { "Content-Type": "application/json" },
            response: true,
            body: { mail },
          });

    request
      .then(response => {
        if (response.status === 200) {
          if (response.data.success === true) {
            this.setState({ delivery: mail });
          } else {
            super.error("Da ging etwas schief");
          }
        } else {
          super.error("Da ging etwas schief");
        }
        scrollToTop();
      })
      .catch(error => {
        this.error(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  showComponent(theme) {
    if (this.state.delivery) {
      return (
        <FormSection theme={theme}>
          <ScrollToTop key="forgot-username-success" />
          <SectionHeader theme={theme}>Benutzername anfordern</SectionHeader>
          <SectionBody theme={theme}>
            <TextBox>
              <Text>
                An die angegebene E-Mail-Adresse {this.state.delivery} wurden
                Benutzernamen gesendet, die mit dieser E-Mail-Adresse bei uns
                registriert sind.
              </Text>
            </TextBox>
          </SectionBody>
          <SectionFooter theme={theme}>
            <SectionFooterPrimaryContent theme={theme}>
              <SubmitButton
                theme={theme}
                onClick={() => {
                  this.setState({ delivery: null }, () => {
                    this.changeState("signIn");
                  });
                }}
              >
                Fertig
              </SubmitButton>
            </SectionFooterPrimaryContent>
          </SectionFooter>
        </FormSection>
      );
    }

    return (
      <FormSection theme={theme}>
        <form onSubmit={this.handleSubmit} noValidate>
          <ScrollToTop key="forgot-username-form" />
          <SectionHeader theme={theme}>Benutzername anfordern</SectionHeader>
          <SectionBody theme={theme}>
            {this.state.delivery === null && (
              <div>
                <FormField theme={theme}>
                  <InputLabel theme={theme}>{I18n.get("Email")} *</InputLabel>
                  <Input
                    autoFocus
                    placeholder={I18n.get("Enter your registered email")}
                    theme={theme}
                    key="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    disabled={this.state.loading}
                    onChange={this.handleInputChange}
                  />
                </FormField>
              </div>
            )}
          </SectionBody>
          <SectionFooter theme={theme}>
            <SectionFooterPrimaryContent theme={theme}>
              <SubmitButton theme={theme} loading={this.state.loading}>
                {I18n.get("Request")}
              </SubmitButton>
            </SectionFooterPrimaryContent>
            <SectionFooterSecondaryContent theme={theme}>
              {this.state.delivery === null && (
                <Link
                  theme={theme}
                  onClick={() => {
                    this.setState({ delivery: null }, () => {
                      this.changeState("signIn");
                    });
                  }}
                >
                  {I18n.get("Back to Sign In")}
                </Link>
              )}
            </SectionFooterSecondaryContent>
          </SectionFooter>
        </form>
      </FormSection>
    );
  }
}
