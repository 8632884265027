import React from "react";

import { I18n, ConsoleLogger as Logger } from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import { AuthPiece } from "aws-amplify-react";
import styled from "@emotion/styled";
import { passwordValidator, ValidationResult } from "@ewe-it/ewe-design-react";
import {
  FormSection,
  SectionHeader,
  SectionBody,
  SectionFooter,
  FormField,
  Input,
  InputLabel,
  Link,
  SectionFooterPrimaryContent,
  SectionFooterSecondaryContent,
} from "aws-amplify-react/lib/Amplify-UI/Amplify-UI-Components-React";

import PhoneField from "aws-amplify-react/lib/Auth/PhoneField";
import { auth } from "aws-amplify-react/lib/Amplify-UI/data-test-attributes";

import countryDialCodes from "aws-amplify-react/lib/Auth/common/country-dial-codes";

import signUpWithUsernameFields, {
  signUpWithEmailFields,
  signUpWithPhoneNumberFields,
} from "aws-amplify-react/lib/Auth/common/default-sign-up-fields";

import { UsernameAttributes } from "aws-amplify-react/lib/Auth/common/types";

import { SubmitButton, ScrollToTop, Box } from "@ewe-it/ewe-design-react";
import { scrollToTop } from "@ewe-it/ewe-frontend-utils";

const logger = new Logger("CustomSignUp");

const ErrorBox = styled(Box)`
  padding: 0 0 20px 0;
`;

export default class CustomSignUp extends AuthPiece {
  constructor(props) {
    super(props);
    this.state = { passwordValidation: [], loading: false };
    this._validAuthStates = ["signUp"];
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sortFields = this.sortFields.bind(this);
    this.getDefaultDialCode = this.getDefaultDialCode.bind(this);
    this.checkCustomSignUpFields = this.checkCustomSignUpFields.bind(this);
    this.needPrefix = this.needPrefix.bind(this);
    this.header =
      this.props && this.props.signUpConfig && this.props.signUpConfig.header
        ? this.props.signUpConfig.header
        : "Create a new account";

    const { usernameAttributes = UsernameAttributes.USERNAME } =
      this.props || {};
    if (usernameAttributes === UsernameAttributes.EMAIL) {
      this.defaultSignUpFields = signUpWithEmailFields;
    } else if (usernameAttributes === UsernameAttributes.PHONE_NUMBER) {
      this.defaultSignUpFields = signUpWithPhoneNumberFields;
    } else {
      this.defaultSignUpFields = signUpWithUsernameFields;
    }
  }

  handleInputChange(event) {
    super.handleInputChange(event);
    if (event.target.name === "password") {
      const passwordValidation = passwordValidator(
        { newPassword1: this.inputs.password },
        false,
      );
      this.setState(prevState => ({ ...prevState, passwordValidation }));
    }
  }

  validate() {
    const invalids = [];
    this.signUpFields.forEach(el => {
      if (el.key !== "phone_number") {
        if (el.required && !this.inputs[el.key]) {
          el.invalid = true;
          invalids.push(el.label);
        } else {
          el.invalid = false;
        }
      } else {
        if (el.required && !this.phone_number) {
          el.invalid = true;
          invalids.push(el.label);
        } else {
          el.invalid = false;
        }
      }
    });
    return invalids;
  }

  sortFields() {
    if (
      this.props.signUpConfig &&
      this.props.signUpConfig.hiddenDefaults &&
      this.props.signUpConfig.hiddenDefaults.length > 0
    ) {
      this.defaultSignUpFields = this.defaultSignUpFields.filter(d => {
        return !this.props.signUpConfig.hiddenDefaults.includes(d.key);
      });
    }

    if (this.checkCustomSignUpFields()) {
      if (
        !this.props.signUpConfig ||
        !this.props.signUpConfig.hideAllDefaults
      ) {
        // see if fields passed to component should override defaults
        this.defaultSignUpFields.forEach((f, i) => {
          const matchKey = this.signUpFields.findIndex(d => {
            return d.key === f.key;
          });
          if (matchKey === -1) {
            this.signUpFields.push(f);
          }
        });
      }

      /* sort fields based on following rules:
                        1. Fields with displayOrder are sorted before those without displayOrder
                        2. Fields with conflicting displayOrder are sorted alphabetically by key
                        3. Fields without displayOrder are sorted alphabetically by key
                */
      this.signUpFields.sort((a, b) => {
        if (a.displayOrder && b.displayOrder) {
          if (a.displayOrder < b.displayOrder) {
            return -1;
          } else if (a.displayOrder > b.displayOrder) {
            return 1;
          } else {
            if (a.key < b.key) {
              return -1;
            } else {
              return 1;
            }
          }
        } else if (!a.displayOrder && b.displayOrder) {
          return 1;
        } else if (a.displayOrder && !b.displayOrder) {
          return -1;
        } else {
          if (a.key < b.key) {
            return -1;
          } else {
            return 1;
          }
        }
      });
    } else {
      this.signUpFields = this.defaultSignUpFields;
    }
  }

  needPrefix(key) {
    const field = this.signUpFields.find(e => e.key === key);
    if (key.indexOf("custom:") !== 0) {
      return field.custom;
    } else if (key.indexOf("custom:") === 0 && field.custom === false) {
      logger.warn(
        "Custom prefix prepended to key but custom field flag is set to false; retaining manually entered prefix",
      );
    }
    return null;
  }

  getDefaultDialCode() {
    return this.props.signUpConfig &&
      this.props.signUpConfig.defaultCountryCode &&
      countryDialCodes.indexOf(
        `+${this.props.signUpConfig.defaultCountryCode}`,
      ) !== "-1"
      ? `+${this.props.signUpConfig.defaultCountryCode}`
      : "+1";
  }

  checkCustomSignUpFields() {
    return (
      this.props.signUpConfig &&
      this.props.signUpConfig.signUpFields &&
      this.props.signUpConfig.signUpFields.length > 0
    );
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!this.inputs.dial_code) {
      this.inputs.dial_code = this.getDefaultDialCode();
    }
    const validation = this.validate();
    if (validation && validation.length > 0) {
      scrollToTop();
      return this.error(
        `The following fields need to be filled out: ${validation.join(", ")}`,
      );
    }

    if (this.inputs["email"] !== this.inputs["confirm_email"]) {
      scrollToTop();
      return this.error("Ihre E-Mail-Adressen stimmen nicht überein");
    }

    if (!Auth || typeof Auth.signUp !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported",
      );
    }

    const validationData = [];
    Object.keys(this.props.validationData).forEach(key => {
      validationData.push({ Name: key, Value: this.props.validationData[key] });
    });

    const signup_info = {
      username: this.inputs.username,
      password: this.inputs.password,
      attributes: {},
      validationData,
    };

    const inputKeys = Object.keys(this.inputs);
    const inputVals = Object.values(this.inputs);

    inputKeys
      .filter(key => key !== "confirm_email")
      .forEach((key, index) => {
        if (
          !["username", "password", "checkedValue", "dial_code"].includes(key)
        ) {
          if (
            key !== "phone_line_number" &&
            key !== "dial_code" &&
            key !== "error"
          ) {
            const newKey = `${this.needPrefix(key) ? "custom:" : ""}${key}`;
            signup_info.attributes[newKey] = inputVals[index];
          }
        }
      });

    if (this.phone_number)
      signup_info.attributes["phone_number"] = this.phone_number;

    let labelCheck = false;
    this.signUpFields.forEach(field => {
      if (field.label === this.getUsernameLabel()) {
        logger.debug(`Changing the username to the value of ${field.label}`);
        signup_info.username =
          signup_info.attributes[field.key] || signup_info.username;
        labelCheck = true;
      }
    });
    if (!labelCheck && !signup_info.username) {
      // if the customer customized the username field in the sign up form
      // He needs to either set the key of that field to 'username'
      // Or make the label of the field the same as the 'usernameAttributes'
      throw new Error(
        `Couldn't find the label: ${this.getUsernameLabel()}, in sign up fields according to usernameAttributes!`,
      );
    }
    this.setState({ loading: true });
    Auth.signUp(signup_info)
      .then(data => {
        this.setState({ loading: false });
        this.changeState("confirmSignUp", {
          username: data.user.username,
          password: this.inputs.password,
        });
      })
      .catch(err => {
        scrollToTop();
        this.setState({ loading: false });
        this.error(err);
      });
  }

  showComponent(theme) {
    if (this.checkCustomSignUpFields()) {
      this.signUpFields = this.props.signUpConfig.signUpFields;
    }
    this.sortFields();
    return (
      <form onSubmit={this.handleSubmit}>
        <FormSection theme={theme} data-test={auth.signUp.section}>
          <ScrollToTop key="sign-up" />
          <SectionHeader theme={theme} data-test={auth.signUp.headerSection}>
            {I18n.get(this.header)}
          </SectionHeader>
          <SectionBody theme={theme} data-test={auth.signUp.bodySection}>
            {this.signUpFields.map(field => {
              return field.key !== "phone_number" ? (
                <React.Fragment key={field.key}>
                  <FormField theme={theme}>
                    {field.required ? (
                      <InputLabel theme={theme}>
                        {I18n.get(field.label)} *
                      </InputLabel>
                    ) : (
                      <InputLabel theme={theme}>
                        {I18n.get(field.label)}
                      </InputLabel>
                    )}
                    <Input
                      autoFocus={
                        this.signUpFields.findIndex(f => {
                          return f.key === field.key;
                        }) === 0
                          ? true
                          : false
                      }
                      autoComplete={(() => {
                        switch (field.key) {
                          case "email":
                            return "email";
                          case "password":
                            return "new-password";
                          case "username":
                            return "username";
                          case "confirm_email":
                            return "email";
                          default:
                            return "off";
                        }
                      })()}
                      placeholder={I18n.get(field.placeholder)}
                      theme={theme}
                      type={field.type}
                      name={field.key}
                      key={field.key}
                      disabled={this.state.loading}
                      onChange={this.handleInputChange}
                      data-test={auth.signUp.nonPhoneNumberInput}
                    />
                  </FormField>
                  {field.key === "password" &&
                    this.state.passwordValidation.length > 0 && (
                      <ErrorBox>
                        <ValidationResult
                          result={this.state.passwordValidation}
                        />
                      </ErrorBox>
                    )}
                </React.Fragment>
              ) : (
                <PhoneField
                  theme={theme}
                  required={field.required}
                  defaultDialCode={this.getDefaultDialCode()}
                  label={field.label}
                  placeholder={field.placeholder}
                  onChangeText={this.onPhoneNumberChanged}
                  key="phone_number"
                />
              );
            })}
          </SectionBody>
          <SectionFooter theme={theme} data-test={auth.signUp.footerSection}>
            <SectionFooterPrimaryContent theme={theme}>
              <SubmitButton
                loading={this.state.loading}
                disabled={this.state.passwordValidation.some(
                  item => !item.valid,
                )}
                data-test={auth.signUp.createAccountButton}
              >
                {I18n.get("Create Account")}
              </SubmitButton>
            </SectionFooterPrimaryContent>
            <SectionFooterSecondaryContent theme={theme}>
              {I18n.get("Have an account? ")}
              <Link
                theme={theme}
                onClick={() => this.changeState("signIn")}
                data-test={auth.signUp.signInLink}
              >
                {I18n.get("Sign in")}
              </Link>
            </SectionFooterSecondaryContent>
          </SectionFooter>
        </FormSection>
      </form>
    );
  }
}
