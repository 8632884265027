import { getUrlParameter } from "@ewe-it/ewe-frontend-utils";

export default {
  container: {
    fontFamily: `"Open Sans", sans-serif`,
  },
  formSection: {
    boxShadow: getUrlParameter("slimmed")
      ? "initial"
      : "0 0 3px 0 rgba(0,0,0,.3)",
    maxWidth: "540px",
    width: "100%",
  },
  sectionHeader: {
    fontSize: "20px",
    lineHeight: "1.1",
    paddingBottom: "40px",
    marginBottom: 0,
  },
  inputLabel: {
    fontSize: "13px",
  },
  input: {
    borderRadius: "2px",
    border: "1px solid #c7c7c7",
    width: "100%",
    outline: "0",
    padding: "8px",
    fontSize: "13px",
  },
  button: {
    height: "42px",
    borderRadius: "2px",
    padding: "0",
    border: "none",
    fontSize: "14px",
    fontWeight: "600",
    cursor: "pointer",
    outlineColor: "#ebebeb",
    transition: "all .3s",
    textDecoration: "none",
    color: "#262626",
    backgroundColor: "#f7cd00",
    textTransform: "none",
    letterSpacing: "normal",
  },
  a: {
    color: "#50bcb7",
    textDecoration: "underline",
    fontWeight: "600",
    fontSize: "13px",
  },
  toast: {
    backgroundColor: "#F7CD00",
    color: "#5f5e5a",
    height: "72px",
  },
  hint: {
    fontSize: "13px",
  },
  sectionFooterSecondaryContent: {
    fontSize: "13px",
  },
};
